import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import Loader from './composants/Loader/Loader';
import './theme.css';
// import App from './App';

//Modification pour un appel en Lazy
const App = lazy(() => import('./App'))

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <HelmetProvider>
      <ErrorBoundary fallback={<div>Une erreur est survenue. Il faut recharger</div>}>
        <Suspense fallback={Loader()}>
          <App />
        </Suspense>
      </ErrorBoundary>

    </HelmetProvider>
    
  </React.StrictMode>
);

