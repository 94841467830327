
import './Loader.css';


function Loader() {
    
  
  return (
    <div className="wrap-barLoader">

      <div className="barLoader-box">
        <div id="loader"></div>
      </div>

    </div>
        
  );
}

export default Loader;

